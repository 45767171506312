import { connectFirestoreEmulator } from '@firebase/firestore';
import {
  browserLocalPersistence,
  connectAuthEmulator,
  getAuth,
  setPersistence,
  type ParsedToken,
  type User,
} from '@firebase/auth';
import { type FirebaseOptions, initializeApp } from '@firebase/app';
import { initializeFirestore } from 'firebase/firestore';
import { defineNuxtPlugin, useRuntimeConfig } from '#app';
import { useStore } from 'vuex';

export default defineNuxtPlugin({
  name: 'firebase',
  dependsOn: ['tracker'],
  async setup() {
    const config = useRuntimeConfig();

    const hasAuthInit = ref<boolean>(false);

    const firebaseConfig: FirebaseOptions = {
      apiKey: config.public.useFirebaseEmulator
        ? 'this-does-not-matter-but-it-does-not-work-without-it'
        : config.public.firebaseApiKey,
      authDomain: config.public.useFirebaseEmulator
        ? config.public.firebaseAuthDomain
        : config.public.firebaseAuthDomain,
      projectId: config.public.useFirebaseEmulator
        ? config.public.rEnvFirebaseEmulatorCi
          ? 'rendin-ci'
          : 'rendin-local'
        : config.public.firebaseProjectId,
      storageBucket: config.public.useFirebaseEmulator
        ? ''
        : config.public.firebaseStorageBucket,
      messagingSenderId: config.public.useFirebaseEmulator
        ? ''
        : config.public.firebaseMessagingSenderId,
      appId: config.public.useFirebaseEmulator ? '' : config.public.firebaseAppId,
      measurementId: config.public.useFirebaseEmulator
        ? ''
        : config.public.firebaseMeasurementId,
    };

    const app = initializeApp(firebaseConfig);

    const auth = getAuth(app);

    const firestoreSettings = config.public.useFirebaseEmulator
      ? { experimentalForceLongPolling: !!config.public.rEnvFirebaseEmulator }
      : {};
    const firestore = initializeFirestore(app, firestoreSettings);

    // Set persistence for auth (if needed)
    // DO NOT AWAIT THIS - sadly plugin setup with await is buggy currently in Nuxt
    setPersistence(auth, browserLocalPersistence);

    // Connect to emulators if applicable
    if (config.public.useFirebaseEmulator) {
      const EMULATOR_PORT = 9099;
      const EMULATOR_HOST = 'http://localhost';

      connectAuthEmulator(
        auth,
        `${config.public.emulatorHost}:${config.public.emulatorPort}`,
      );
      connectFirestoreEmulator(firestore, EMULATOR_HOST, EMULATOR_PORT);
    }

    const store = useStore();
    // Setup auth listeners, which will relay information to users store
    if (import.meta.client) {
      auth.onAuthStateChanged(async (next) => {
        hasAuthInit.value = true;

        await refreshAuth(next);
      });
    }

    //

    type UntrustedAuthCookie = {
      sessionUser?: {
        email: string;
        uid: string;
        isAnonymous: boolean;
        emailVerified: boolean;
      };
    };

    const untrustedAuthCookie = useCookie<UntrustedAuthCookie | null>(
      'r-session-user-untrusted',
      {
        // cookie lives for 30 days
        maxAge: 60 * 60 * 24 * 30,
        path: '/',
        secure: true,
      },
    );

    const refreshAuth = async (
      user: User | null,
      overrideClaims?: ParsedToken | null,
    ) => {
      const claims =
        overrideClaims ?? (user ? (await user.getIdTokenResult(true)).claims : null);

      store.dispatch('users/onAuthStateChangedAction', {
        authUser: user,
        claims: claims,
      });
      store.commit('users/ON_AUTH_STATE_CHANGED_MUTATION', {
        authUser: user,
        claims: claims,
      });

      untrustedAuthCookie.value = {
        sessionUser: store.state.users.user,
      };
    };

    // Firebase Auth hasn't loaded yet, so we use local value in meantime
    if (untrustedAuthCookie.value && !hasAuthInit.value) {
      try {
        const { sessionUser } = untrustedAuthCookie.value;

        store.commit('users/UPDATE_SESSION_USER', {
          sessionUser: sessionUser,
        });
      } catch (error) {
        console.log('ERROR: Failed to parse auth cookie: ', error);
      }
    }

    return {
      provide: {
        auth: auth,
        firestore: firestore,
      },
    };
  },
});
